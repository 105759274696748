import React from 'react'
import { Flex } from '@chakra-ui/react'

const windowGlobal = typeof window !== 'undefined' && window

function goBack() {
  if(!!windowGlobal)
  {
    windowGlobal.history?.back();
  }
}

const AudioBookPage = (props) => {
  const location = props['location'];
  const source = location.hasOwnProperty('state') ? location.state['source'] : '';

  return (
    <Flex className={'container-main'}
          padding={'5rem 2rem'}
          shrink={true}
    >
      <button onClick={goBack}>
        Go back
      </button>
      <audio controls="controls"
             autoPlay
             playsInline
             style={{
               width: '100%',
               bottom: '0'
             }}
      >
        <source src={source} type="audio/mpeg"/>
      </audio>
    </Flex>
  )
}

export default AudioBookPage
